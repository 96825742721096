<template>
    <b-card title="Send Password Reset Link">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- email -->
                    <b-col cols="12">
                        <b-form-group label="Email" label-for="fh-email">
                            <validation-provider name="Email" rules="required|max:50|email" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MailIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="fh-email" v-model="email" type="email"
                                        :state="errors.length > 0 ? false : null" placeholder="Email" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-form-text class="text-danger">
                                Enter a Valid Email
                            </b-form-text>
                        </b-form-group>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BInputGroup,
    BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required, email, confirmed, password
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        ValidationProvider,
        ValidationObserver,
        required,
        email,
        confirmed,
        password,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            email,
            password: "",
            passworddate: "",
            otp: "",
            error: "",
            dd: "",
            d: "",
            mm: "",
            yyyy: "",
            name: "",
            passwordupdatedate: "",
            uid: "",
            a: ""
        }
    },
    methods: {
        validationForm() {

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
            db.collection("users")
                .where("email", "==", this.email)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.size > 0) {
                        querySnapshot.forEach((doc) => {
                            console.log(doc.id, " => ", doc.data());
                            //console.log(doc.data().size)
                            this.passworddate = doc.data().email;
                            this.uid = doc.data().user_id;
                            this.name = doc.data().firstname;
                            this.otp = Math.floor(100000 + Math.random() * 900000);
                            this.today = new Date();
                            this.a = 1;
                            this.d = String(this.today.getDate()).padStart(2, '0');
                            this.dd = parseInt(this.d) + parseInt(this.a);
                            this.mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
                            this.yyyy = this.today.getFullYear();
                            this.passwordupdatedate = this.mm + '/' + this.dd + '/' + this.yyyy;

                            /* update user table */
                            firebase.auth().sendPasswordResetEmail(this.email);
                            store.dispatch('app/commitDeactivateLoader');
                            this.showNotification('Success, Please Check Your Email For Reset Password!', 'UserIcon', 'success');
                            this.email = "";

                            /*End update user table */

                        });
                    } else {
                        store.dispatch('app/commitDeactivateLoader');
                        this.showNotification('Error! Email doesn\'t exist', 'UserIcon', 'danger');
                    }
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                    store.dispatch('app/commitDeactivateLoader');
                    this.showNotification('Error! Something went wrong', 'UserIcon', 'danger');

                });


        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>